import { mapGetters } from "vuex";
import Group from "./Group.vue";
import Play from "./Play.vue";

export default {
    name: "Groups",
    components: {
        Group,
        Play,
    },
    data() {
        return {
            newGroup: false,
            playGroup: null,
        };
    },
    computed: {
        // @ts-ignore
        ...mapGetters({
            groups: "Group/edit/groups",
        }),
    },
    methods: {
        refresh(force = true) {
            this.$store.dispatch("Group/edit/getList", force);
        },
        play(group) {
            this.playGroup = group;
        },
    },
    mounted() {
        this.refresh(false);
    },
};
