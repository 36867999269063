import { mapGetters } from "vuex";
import FlatPickr from 'vue-flatpickr-component';
import { French } from 'flatpickr/dist/l10n/fr.js';
import dayjs from "dayjs";
import Multiselect from 'vue-multiselect';
import * as Config from "@/config/constants";
import {
    Chart,
    LineController,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    Legend,
} from "chart.js";


const DEFAULT_DAYS_RANGE = 7;
const DEFAULT_RANGE = [
    dayjs().subtract(DEFAULT_DAYS_RANGE, 'day'),
    dayjs().subtract(1, 'day'),
]


Chart.register(
    LineElement,
    LineController,
    CategoryScale,
    LinearScale,
    PointElement,
    Legend,
);
let chart = null;


export default {
    name: "Play",
    props: ["group"],
    components: {
        FlatPickr,
        Multiselect,
    },
    data() {
        return {
            passedData: [],
            futureData: [],
            dateConfig: {
                locale: French,
                mode: 'range',
            },
            defaultDate: DEFAULT_RANGE.map(date => date.format(Config.DATE_FORMAT)),
            filters: {
                range: DEFAULT_RANGE,
                project: null,
            },
        };
    },
    computed: {
        // @ts-ignore
        ...mapGetters({
            resourcesAll: "Resource/list/resourcesAll",
            projects: 'Project/list/projects',
        }),
        data() {
            if (!this.passedData.length || !this.futureData.length) return [];
            const data = this.group.userIds.map(userId => ({
                user: this.resourcesAll.find(user => user.id === parseInt(userId)),
                passed: this.passedData.find(passedRow => passedRow.user_id === userId),
                future: this.futureData.find(futureRow => futureRow.user_id === userId),
            }));
            data.sort((a, b) => {
                const aProgress = a.passed ? parseFloat(a.passed.avg_progress) : 0;
                const bProgress = b.passed ? parseFloat(b.passed.avg_progress) : 0;
                if (aProgress < bProgress) return 1;
                if (aProgress > bProgress) return -1;
                return 0;
            });
            return data;
        },
        range() {
            return this.filters.range.map(date => date.format(Config.DATE_FORMAT));
        },
        selectedRangeDays() {
            return this.filters.range[1].diff(this.filters.range[0], 'day') + 1;
        },
    },
    methods: {
        refresh(selectedRange) {
            if (Array.isArray(selectedRange)) {
                if (selectedRange.length !== 2) return;
                this.filters.range = selectedRange.map(date => dayjs(date));
            }
            const payload = {
                groupId: this.group.id,
                params: {
                    from: this.range[0],
                    to: this.range[1],
                    projectId: this.filters.project ? this.filters.project.id : null,
                },
            };
            this.$store.dispatch("Group/edit/game", payload).then(data => {
                this.passedData = data;
            });
            this.refreshChart();
        },
        refreshChart() {
            if (chart) chart.destroy();
            const payload = {
                groupId: this.group.id,
                params: {
                    from: this.range[0],
                    to: this.range[1],
                    projectId: this.filters.project ? this.filters.project.id : null,
                    chart: '',
                },
            };
            if (this.selectedRangeDays < 60) return;
            this.$store.dispatch("Group/edit/game", payload).then(data => {
                const nbUsers = this.group.userIds.length;
                const datasets = this.group.userIds.map((userId, index) => {
                    const hue = Math.round(340 / nbUsers * index);
                    return {
                        label: this.resourcesAll.find(user => user.id === parseInt(userId)).fullname,
                        data: data.filter(row => row.user_id === userId),
                        borderColor: `hsl(${hue}, 50%, 50%)`,
                        backgroundColor: `hsl(${hue}, 50%, 70%)`,
                    };
                });
                chart = new Chart(this.$refs.chart, {
                    type: 'line',
                    data: { datasets, },
                    options: {
                        parsing: {
                            xAxisKey: 'month',
                            yAxisKey: 'avg_progress',
                        },
                        animation: false,
                        scales: {
                            y: {
                                title: {
                                    display: true,
                                    text: 'Progression moyenne',
                                },
                                ticks: {
                                    callback(value: any, index, values) {
                                        return (value * 100) + '%';
                                    }
                                },
                            },
                            x: {
                                ticks: {
                                    callback(value, index, values) {
                                        const label = this.getLabelForValue(value);
                                        return dayjs(label + '-01').format('MMM YYYY');
                                    }
                                },
                            },
                        },
                        //@ts-ignore
                        plugins: false
                    }
                });
            });
        },
        rangeShortcut(daysRange) {
            this.filters.range = [
                dayjs().subtract(daysRange, 'day'),
                dayjs().subtract(1, 'day'),
            ];
            this.$refs.date.fp.setDate(this.filters.range.map(date => date.format(Config.DATE_FORMAT)));
            this.refresh();
        },
        unselectProject() {
            this.filters.project = null;
            this.refresh();
        },
    },
    mounted() {
        this.$store.dispatch('Project/list/getList');
        this.refresh();
        this.$store.dispatch("Group/edit/gameFuture", this.group.id).then((data) => {
            this.futureData = data;
        });
    },
};
