import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import IconDelete from "@/icons/minus-square-solid.svg";

export default {
    name: 'Group',
    components: {
        Multiselect,
        IconDelete,
    },
    props: {
        group: {
            default() {
                return {
                    name: '',
                    userIds: [],
                };
            }
        },
        edit: {
            default: false
        },
    },
    data() {
        return {
            editMode: false,
        };
    },
    computed: {
        // @ts-ignore
        ...mapGetters({
            resourcesAll: "Resource/list/resourcesAll",
            resources: "Resource/list/resources",
        }),
        creating() {
            return !this.group.id;
        },
        userIds() {
            return this.group.userIds.map(id => parseInt(id));
        },
        selectableResources() {
            return this.resources
                .filter(resource => !this.userIds.includes(resource.id));
        },
        users() {
            return this.userIds.map(userId => this.resourcesAll
                .find(resource => resource.id === userId) || { id: userId, fullname: 'Disabled', deleted: true });
        },
    },
    methods: {
        submit() {
            const module = this.creating ? 'create' : 'update';
            const group = {
                name: this.group.name,
                user_ids: this.group.userIds,
            }
            const payload = this.creating ? group : { groupId: this.group.id, group };
            this.$store.dispatch(`Group/edit/${module}`, payload).then(() => {
                this.editMode = false;
                this.$emit('submit');
            });
        },
        cancel() {
            this.editMode = false;
            this.$emit('cancel');
        },
        addUser(resource) {
            this.group.userIds.push(resource.id);
        },
        removeUser(index) {
            this.group.userIds.splice(index, 1);
        },
        removeGroup() {
            if (!window.confirm('Sure ?')) return;
            this.$store.dispatch('Group/edit/remove', this.group.id).then(() => {
                this.$emit('delete');
            });
        },
    },
    mounted() {
        this.editMode = this.edit;
    }
}
